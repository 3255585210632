@charset "UTF-8";
@import "mixin";

// ▼▼▼ index ▼▼▼
// UPアイコン .release
// レシピ数カウンター .cmn_recipe_counter
// バナースライダー .bnrbox
// 詳細検索 .recipe_contents_condition
// ランキングコンテンツ .ranking
// おすすめレシピコンテンツ .recommend
// ランキング等のタイトル .cmn_ttl
// 概要系ページのページタイトル .pagettl_box
// メインビジュアル .mainvisual
// カテゴリごとのインデックス .category_index
// ページ送り .pager


// UPアイコン
.release {
	@include fz(12);
	margin: 0 0 5px 0;
	display: block;
	&-right {
		margin: 0 0 5px auto;
		text-align: right;
	}
	&-recipesearch {
		margin: 0 0 10px auto;
		text-align: right;
    @include sp {
      margin: 0 10px 10px auto;
    }
	}
	&--icon {
		margin: 0 0 0 5px;
		position: relative;
		top: 2px;
		display: inline-block;
	}
}

// 新着情報・更新情報・レシピ変更情報　リスト
.new_release_list {
  list-style: none;
  width: 690px;
  margin: 0 0 30px 0;
  @include sp {
    width: 90%;
    margin: 0 auto 30px auto;
  }
  &-backnumber {
    width: auto;
    .new_release_list--item {
      border-bottom-color: #ccc;
    }
    .new_release_list--description {
      width: calc(100% - 100px);
      @include sp {
        width: auto;
      }
    }
    @include sp {
      width: 90%;
      margin: 0 auto 30px auto;
    }
  }
  // レシピ変更情報の検索結果
  &-result {
    .new_release_list--item {
      border-bottom: none;
      margin-bottom: 30px;
    }
    @include sp {
      width: auto;
    }
  }
  //フェードアウトの処理
  &.box-fadeout {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left:0;
      width: 100%;
      height: 120px;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+50,ffffff+90&0+0,1+50 */
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 90%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 90%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
  }
  &--item {
    border-bottom: 2px dashed #fff;
    padding: 15px 0 10px 0;
    opacity: 1;
    height: auto;
    margin: auto;
    transition: .3s;
    &.is-hidden {
      opacity: 0;
      height: 0;
      margin: 0;
      padding: 0;
      border: 0;
    }
  }
  &--more {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 222px;
    height: 40px;
    border-radius: 20px;
    padding: 0 10px 0 0;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: $colorYellow;
    @include fz(13);
    color: $colorBrown;
    font-weight: 700;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.3);
    border: 0;
    cursor: pointer;
    transition: .2s;
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom: 0;
      right: 15px;
      margin: auto 0;
      width: 5px;
      height: 5px;
      border-top: 1px solid $colorBrown;
      border-right: 1px solid $colorBrown;
      transform: rotate(135deg);
    }
    &:hover {
      background-color: darken($colorOrange, 5%);
      box-shadow: 0 0 0 #42210B;
    }
    @include sp {
      margin: -30px auto 30px auto;
    }
    &.is-btn-hidden {
      display: none;
    }
  }
  &--link {
    position: relative;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
    &:after {
      position: absolute;
      top: -8px;
      left: -8px;
      opacity: 0;
      background-color: #c89b05;
      content: "";
      display: block;
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      border-radius: 5px;
      transition: .15s;
      transform: scale(.9, .9);
    }
    &:hover {
      &:after {
        opacity: .3;
        transform: scale(1, 1);
      }
    }
    @include sp {
      display: block;
      .release {
        display: block;
        margin: 0 0 5px 0;
      }
    }
  }
  &--nolink {
    position: relative;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
  }
  &--release {
    @include fz(12);
    color: #505050;
    margin: 5px 0;
    display: block;
  }
  &--description {
    width: 580px;
    @include fz(14);
    line-height: 1.5;
    @include sp {
      width: auto;
    }
  }
}

// レシピ数カウンター
.cmn_recipe_counter {
  &--ttl {
    @include fz(13);
    margin: 0 0 5px 0;
    @include sp {
      @include fz(10);
      margin-bottom:0;
    }
  }
  &--link {
    position: relative;
    display: block;
    text-decoration: none;
    color: $colorSidenav;
    &:before {
      content: "";
      position: absolute;
      top:0;
      bottom: 0;
      margin: auto 0;
      left: -22px;
      width: 16px;
      height: 22px;
      border-radius: 5px;
      background-color: transparent;
      transition: .15s;
    }
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom: 0;
      margin: auto 0;
      left: -20px;
      width: 6px;
      height: 6px;
      border-top: 2px solid $colorSidenav;
      border-right: 2px solid $colorSidenav;
      transform: rotate(45deg);
    }
    &:hover {
      &:before {
        background-color: $colorYellow;
      }
    }
    @include sp {
      &:after {
        left:-10px;
        width: 4px;
        height: 4px;
      }
      &:hover {
        &:before {
          content: none;
        }
      }
    }
  }
  &--num {
    display: inline-block;
    @include fz(34);
    font-weight: 700;
    @include sp {
      @include fz(28);
    }
  }
  &--unit {
    display: inline-block;
    @include sp {
      width: 45px;
    }
  }
}

//検索窓
.searchbox {
  position: relative;
  display: flex;
  align-items: center;
  @include sp {
    justify-content: flex-end;
    margin: 0 10px;
    width: 100%;
    box-sizing: border-box;
  }
  &:before {
    position: absolute;
    top: 4px;
    right: 5px;
    background-color: #f6883f;
    content: "";
    width: 28px;
    border: 0;
    -webkit-appearance: none;
    height: 28px;
    border-radius: 50%;
  }
  &:after {
    @include icon2;
    @include fz(14);
    position: absolute;
    top: 11px;
    right: 11px;
    content: $icon-search;
    color: #fff;
  }
  &--search_tag {
    @include fz(14);
    padding: 0 15px;
    background-color: #eeeae6;
    -webkit-appearance: none;
    border-radius: 30px;
    border: 0;
    min-width: 300px;
    box-sizing: border-box;
    color: #000;
    height: 36px;
    transition: .5s;
    @include sp {
      @include fz(16);
      width: 225px;
      min-width: 225px;
      //min-width: 220px;
      &:focus {
        box-shadow: 0 0 5px rgba(0, 0, 0, .3) inset;
        width: 100%;
        //min-width: 350px;
      }
    }
  }
  &--submit_tag {
    position: absolute;
    top: 4px;
    right: 5px;
    background-color: transparent;
    width: 28px;
    border: 0;
    -webkit-appearance: none;
    height: 28px;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;
  }
}

//バナースライダー
.bnrbox {
  //SPのみ表示
  &-forsp {
    display: none;
    @include sp {
      position: relative;
      overflow: hidden;
      display: block;
      padding: 20px 0 10px 0;
    }
  }
  //SP・PCともに表示
  &-forpc {
    display: block;
    padding: 30px 0;
    @include sp {
      padding: 20px 0 10px 0;
    }
  }
  &--inner {
    position: relative;
    //left: -125px;
    width: 750px;
    margin: 0 auto;
    overflow: hidden;
    @include sp {
      //width: 320px;
      width: 100%;
      //left:auto;
      overflow: hidden;
    }
  }
  &--link {
    position: relative;
    display: block;
    width: 240px;
    margin-right:10px;
    line-height: 0;
    border: 1px solid #ccc;
    transition: .15s;
    &:hover {
      border: 1px solid $colorBrown;
      filter: brightness(105%);
    }
    @include sp {
      width: 48%;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
  &--img {
    width: 100%;
  }
  .flickity-page-dots {
    position: relative;
    width: 750px;
    margin: 10px auto 0 auto;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    @include sp {
      display: flex;
      width: 100%;
      margin-bottom:10px;
    }
  }
  .dot {
    display: block;
    border:2px solid $colorBrown;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-sizing: border-box;
    margin: 0 10px 0 0;
    transition: .15s;
    &:hover,
    &.is-selected {
      background-color: #facd39;
    }
    @include sp {
      width: 10px;
      height: 10px;
    }
  }
  .flickity-button {
    display: none;
  }
}

// 詳細検索
.recipe_contents_condition {
  // 基本はindex.php詳細検索用
  &-index {
    .recipe_contents_condition--submit_area {
      @include sp {
        width: 100%;
        height: 75px;
        display: flex;
        height: 70px;
        justify-content: center;
        align-items: center;
        background-color: $colorSidenav;
      }
    }
  }
  // ↓list.php詳細検索用
  &-list {
    @include sp {
      padding-bottom:70px;
      box-sizing: border-box;
      .recipe_contents_condition--fixedarea {
        position: fixed;
        bottom:0;
        left:0;
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $colorSidenav;
      }
    }
  }
  &--inner {
    position: relative;
    background-color: #fff;
    padding: 10px 0;
    @include sp {
      padding:0;
    }
  }
  &--intro {
    @include fz(12);
    line-height: 1.4;
    text-align: center;
    color: $colorBrown;
    padding: 0 0 20px 0;
    @include sp {
      display: none;
    }
  }
  &--col {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #efebe8;
    width: 895px;
    margin: 0 auto;
    padding: 5px 0 10px 0;
    @include sp {
      display: block;
      width: 100%;
      padding: 0;
    }
  }
  &--l {
    position: relative;
    @include fz(14);
    font-weight: 700;
    color: $colorSidenav;
    width: 180px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include sp {
      width: auto;
      background-image: url("/container/common/images/bg-main-content.png");
      padding: 12px 15px;
      border-bottom: 1px solid #fff;
      transition: .15s;
    }
    &-toggle {
      pointer-events: none;
      @include sp {
        pointer-events: auto;
        &:after {
          content: "";
          position: absolute;
          top:0;
          bottom:0;
          right: 20px;
          margin: auto 0;
          width: 7px;
          height: 7px;
          border-top: 2px solid #000;
          border-right: 2px solid #000;
          transform: rotate(135deg);
          transition: .15s;
        }
        &.active {
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  &--r {
    width: 715px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include fz(14);
    @include sp {
      //slideToggle で block
      display: none;
      width: 100%;
      box-sizing: border-box;
      background-color: #fff;
      transform: .3s;
    }
    //最初から開いているボックス
    &-open {
      display: block;
    }
  }
  &--r_inner {
    @include sp {
      padding:10px;
    }
  }
  &--package {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include sp {
      align-items: stretch;
      &-box1,
      &-box2 {
        .recipe_contents_condition--label {
          &:last-child,
          &:nth-last-child(2) {
            border-bottom: none;
          }
        }
      }
      &-box3,
      &-box4 {
        .recipe_contents_condition--label {
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
  &--2cols {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 10px 0;
    @include sp {
     padding-left:10px;
    }
  }
  &--freeword {
    position: relative;
  }
  &--input_freeword {
    @include fz(14);
    padding: 0 15px;
    background-color: #eeeae6;
    -webkit-appearance: none;
    border-radius: 30px;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    color: #000;
    height: 46px;
    transition: .5s;
    &::placeholder {
      color: #949494;
    }
    &:focus {
      box-shadow: 0 0 5px rgba(0, 0, 0, .3) inset;
      min-width: 305px;
    }
    @include sp {
      @include fz(16);
    }
  }
  &--description {
    @include fz(12);
    line-height: 1.2;
    color: #b3b3b3;
    padding: 10px 0 0 0;
  }
  &--label {
    margin: 0 15px 5px 0;
    padding: 0;
    color: #505050;
    cursor: pointer;
    transition: .15s;
    &:hover {
      color: darken($colorYellow, 10%);
    }
    @include sp {
      width: 33%;
      box-sizing: border-box;
      padding: 10px 2px;
      margin: 0;
      border-right: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      align-items: center;
      @include fz(14);
      line-height: 1.2;
      color: #505050;
      cursor: pointer;
      transition: .15s;
      &:nth-child(3n) {
        border-right: none;
      }
    }
  }
  &--radiobox {
    position: relative;
    top: 2px;
    border: 1px solid #505050;
    appearance: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: .15s;
    &:after {
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: darken($colorYellow, 10%);
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0, 0);
    }
    &:checked {
      border-radius: 50%;
      &:after {
        animation: inputFade .2s cubic-bezier(.895, .03, .685, .22) forwards;
      }
    }
    @keyframes inputFade {
      0% {
        transform: scale(0, 0);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
  &--checkbox {
    position: relative;
    top: 5px;
    border: 1px solid #505050;
    appearance: none;
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-right:5px;
    transition: .15s;
    &:after {
      position: absolute;
      top: 2px;
      left: 2px;
      background-color: darken($colorYellow, 10%);
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      transform: scale(0, 0);
    }
    &:checked {
      &:after {
        animation: inputFade .2s cubic-bezier(.895, .03, .685, .22) forwards;
      }
    }
    @keyframes inputFade {
      0% {
        transform: scale(0, 0);
      }
      100% {
        transform: scale(1, 1);
      }
    }
    @include sp {
      top:0;
    }
  }
  &--select_box {
    margin: 5px 3px 0 0;
    position: relative;
    display: inline-block;
    top: 0;
    background-color: #eeeae6;
    height: 34px;
    width: 190px;
		overflow: hidden;
    @include fz(14);
    &:before {
      position: absolute;
      top: 8px;
      right: 10px;
      content: "";
      display: block;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent #725c50 transparent;
    }
    &:after {
      position: absolute;
      right: 10px;
      bottom: 8px;
      content: "";
      display: block;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: #725c50 transparent transparent transparent;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    @include sp {
      width: calc(50% - 5px);
      margin: 0 10px 10px 0;
      height: 44px;
      &:nth-child(3n) {
        margin-right: auto;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    &-wide {
      width: 290px;
      @include sp {
        width: 100%;
      }
    }
    &-sp {
      display: none;
      @include sp {
        display: block;
        width: 100%;
      }
    }
  }
  &--select {
    appearance: none;
    background-color: transparent;
    @include fz(14);
    padding: 5px 10px;
    width: 100%;
    box-sizing: border-box;
    border: 0;
    height: 34px;
		overflow: hidden;
    @include sp {
      height: 44px;
    }
  }
  &--input_box {
    display: inline-block;
  }
  &--input {
    position: relative;
    display: inline-block;
    @include fz(14);
    margin: 5px 10px 0 0;
    padding: 5px 10px;
    background-color: #eeeae6;
    width: 190px;
    border: 0;
    height: 34px;
    box-sizing: border-box;
    z-index: 100;
    @include sp {
      margin: 0 0 10px 0;
      width: calc(50% - 10px);
      height: 44px;
      border: 0;
    }
  }
  &--type {
    position: relative;
    top: 5px;
    display: inline-block;
    @include fz(18);
    font-weight: 700;
    @include sp {
      top:15px;
      @include fz(16);
    }
  }
  &--txt {
    display: inline;
    margin: 0 0 0 10px;
    position: relative;
    top: 4px;
    @include fz(14);
    line-height: 1.3;
    @include sp {
      @include fz(12);
      display: inline-block;
      width: 40%;
      top:10px;
    }
  }
  &--submit_box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorYellow;
    width: 400px;
    height: 60px;
    margin: 15px auto;
    border-radius: 30px;
    transition: .15s;
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      right: 30px;
      width: 7px;
      height: 7px;
      margin: auto 0;
      border-top: 2px solid $colorBrown;
      border-right: 2px solid $colorBrown;
      transform: rotate(45deg);
      pointer-events: none;
      transition: .15s;
    }
    &:hover {
      filter: contrast(120%);
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
    }
    @include sp {
      width: 90%;
      height: 50px;
      border-radius: 25px;
      margin: auto;
    }
  }
  &--submit {
    @include fz(18);
    font-weight: 700;
    background-color: transparent;
    width: 100%;
    border: 0;
    color: $colorBrown;
    appearance: none;
    height: 60px;
    cursor: pointer;
    transition: .15s;
    @include sp {
      height: 50px;
    }
  }
}

//ランキングコンテンツ
.ranking {
  position: relative;
  background-image: url("/container/common/images/cmn-bg-stripe.png");
  &--inner {
    width: 800px;
    margin: 0 auto;
    padding: 40px 0 80px 0;
    @include sp {
      width: 100%;
      padding-bottom:120px;
    }
  }
  &--ttl {
    background-image: url("/container/common/images/ranking-ttl.png");
    background-repeat: no-repeat;
    background-position: top center;
    padding: 80px 0 40px 0;
    text-align: center;
    @include fz(28);
    font-weight: 700;
    color: $colorSidenav;
  }
  &--wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    &:after {
      content: "";
      width: 120px;
      height: 80px;
      background-image: url("/container/common/images/charactor01.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      bottom:-20px;
      right: -100px;
    }
    @include sp {
      display: block;
      &:after {
        bottom:-100px;
        right:35%;
      }
    }
  }
  &--content {
    position: relative;
    width: 370px;
    background-color: #fff;
    border-top: 3px solid $colorBrown;
    &:before {
      content: "";
      position: absolute;
      top:-20px;
      left:0;
      right:0;
      margin: 0 auto;
      width: 205px;
      height: 40px;
      background-image: url("/container/common/images/ranking-ribbon.png");
      background-repeat: no-repeat;
      background-position: top center;
      z-index: 5;
    }
    @include sp {
      position: static;
      width: 90%;
      min-width: 320px;
      margin: 0 auto;
      line-height: 0;
      // ～contentが重なった時のズレ防止
      &-general {
        border-top: none;
      }
    }
  }
  &--content_tab {
    @include sp {
      position: absolute;
      top:3px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      width: 45%;
      height: 95px;
      background-color: #ede9d9;
      &.active {
        width: 45%;
        background-color: #fff;
        box-shadow: 0 0 0 0;
      }
      &-newstock {
        left:5%;
      }
      &-total {
        right:5%;
      }
    }
  }
  &--ttl2 {
    padding: 40px 20px;
    @include fz(18);
    color: $colorBrown;
    @include sp {
      padding: 15px 0;
      @include fz(16);
    }
  }
  &--ttl2_delete {
    @include sp {
      display: none;
    }
  }
  &--release {
    position: absolute;
    top: 42px;
    right: 20px;
    @include sp {
      position: relative;
      top:auto;
      right:auto;
    }
  }
  &--list {
    list-style: none;
    padding: 0 10px;
    @include sp {
      margin: 95px 0 0 0;
    }
    &-newstock {
      @include sp {
        display: none;
        &.current {
          display: block;
        }
      }
    }
    &-total {
      @include sp {
        display: none;
        &.current {
          display: block;
        }
      }
    }
  }
  &--item {
    position: relative;
    border-top: 1px dotted #ddd5cd;
    box-sizing: border-box;
  }
  &--pic {
    position: absolute;
    top:0;
    bottom: 0;
    left: 10px;
    margin: auto 0;
    height: 80px;
  }
  &--link {
    position: relative;
    display: flex;
    align-items: center;
    height: 110px;
    padding: 0 0 0 140px;
    color: #000;
    @include fz(14);
    font-weight: 700;
    line-height: 1.3;
    text-decoration: none;
    &:after {
      position: absolute;
      top: 5px;
      left: -5px;
      opacity: 0;
      background-color: #c89b05;
      content: "";
      display: block;
      width: calc(100% + 10px);
      height: calc(100% - 10px);
      border-radius: 5px;
      transition: .15s;
      transform: scale(.9, .9);
    }
    &:hover {
      &:after {
        opacity: .3;
        transform: scale(1, 1);
      }
    }
  }
  &--rank {
    display: block;
    position: absolute;
    top: auto;
    bottom:auto;
    left: 110px;
    @include fz(16);
    color: #fff;
    z-index: 1;
    &:before {
      @include icon2;
      content: $icon-crown;
      @include fz(38);
      position: absolute;
      top: -14px;
      left: -14px;
      z-index: -1;
    }
    &-gold {
      &:before {
        color: #d7a447;
      }
    }
    &-blue {
      &:before {
        color: #607b97;
      }
    }
    &-copper {
      &:before {
        color: #9e5331;
      }
    }
    &-grey {
      &:before {
        color: #b2b2b2;
      }
    }
  }
}

//おすすめレシピコンテンツ
.recommend {
  position: relative;
  overflow: hidden;
  padding: 50px 0 100px 0;
  margin: 0 0 10px 0;
  background-color: #fff;
  &:after {
    content: "";
    width: 170px;
    height: 135px;
    background-image: url("/container/common/images/charactor02.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: calc(50% - 480px);
  }
  @include sp {
    &:after {
      left: 20%;
    }
  }
  &--inner {
    position: relative;
    width: 960px;
    margin: 0 auto;
    @include sp {
      width: 100%;
    }
  }
  &--release {
    position: absolute;
    top:90px;
    right:40px;
    @include sp {
      top:auto;
      bottom:5px;
      right:15px;
      left:0;
      text-align: right;
    }
  }
  &--slider {
    position: relative;
    margin: 0 0 40px 0;
  }
  &--slider_inner {
    position: relative;
    width: 100%;
  }
  &--cover {
    display: block;
    width: 20%;
    height: 100%;
    position: absolute;
    top:0;
    pointer-events: none;
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
    &-r {
      right:0;
      transform: rotate(180deg);
    }
    &-l {
      left:0;
    }
  }
  &--item {
    position: relative;
    display: block;
    overflow: hidden;
    width: 220px;
    color: $colorBrown;
    text-decoration: none;
    padding: 10px;
    box-sizing: border-box;
    transition: .15s;
    &:hover {
      background-color: rgba(200, 155, 5, .3);
      border-radius: 10px;
    }
  }
  &--pic {
    display: block;
    width: 200px;
    margin: 0 0 10px 0;
  }
  &--name {
    display: flex;
    align-items: center;
    @include fz(16);
    line-height: 1.3;
    color: $colorBrown;
    min-height: 50px;
    margin: 0 0 10px 0;
  }
  &--description {
    @include fz(14);
    line-height: 1.5;
  }
  &--arw {
    position: absolute;
    top:calc(50% - 25px);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color:$colorBrown;
    border:none;
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    transition: .3s;
    &:before {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      margin: auto 0;
      width: 10px;
      height: 10px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
    }
    &-l {
      left:10%;
      &:before {
        left:20px;
        transform: rotate(-135deg);
      }
      &:hover {
        left:9%;
      }
      @include sp {
        left:3%;
        &:hover {
          left:2%;
        }
      }
    }
    &-r {
      right:10%;
      &:before {
        right:20px;
        transform: rotate(45deg);
      }
      &:hover {
        right:9%;
      }
      @include sp {
        right:3%;
        &:hover {
          right:2%;
        }
      }
    }
  }
}

//ランキング等のタイトル
.cmn_ttl {
  background-image: url("/container/common/images/ranking-ttl.png");
  background-repeat: no-repeat;
  background-position: top center;
  padding: 80px 0 40px 0;
  text-align: center;
  @include fz(24);
  font-weight: 700;
  color: $colorSidenav;
  @include sp {
    @include fz(20);
  }
}

.passage_ttl {
  @include fz(18);
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  color: #fff;
  background-image: url("/container/recipesearch/images/recipe-bg-bar.png");
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 0;
  margin: 50px 0 20px 0;
  @include sp {
    @include fz(16);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 20px 0;
    margin-top: 30px;
  }
}

//概要系ページのページタイトル
.pagettl_box {
  position: relative;
  width: 100%;
  background-image: url("/container/common/images/bg-pagettl.png");
  &-testkitchen {
  }
  &--txt {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 960px;
    height: 220px;
    margin: 0 auto;
    @include fz(28);
    color: $colorBrown;
    &:before {
      content: "";
      position: absolute;
      bottom:0;
      right:20px;
      background-size: cover;
    }
    @include sp {
      width: 100%;
      height: 140px;
      @include fz(16);
      padding-left:15px;
      box-sizing: border-box;
    }
    &-faq {
      &:before {
        background-image: url("/container/faq/images/pagettl-pic.png");
        background-repeat: no-repeat;
        background-position: center center;
        width: 260px;
        height: 165px;
      }
      @include sp {
        &:before {
          width: 156px;
          height: 99px;
        }
      }
    }
    &-company {
      &:before {
        background-image: url("/container/company/images/pagettl-pic.png");
        background-repeat: no-repeat;
        background-position: center center;
        width: 260px;
        height: 140px;
      }
      @include sp {
        &:before {
          width: 156px;
          height: 84px;
        }
      }
    }
    &-contact {
      &:before {
        background-image: url("/container/contact/images/pagettl-pic.png");
        background-repeat: no-repeat;
        background-position: center center;
        width: 260px;
        height: 150px;
      }
      @include sp {
        &:before {
          width: 200px;
          height: 115px;
        }
      }
    }
    &-form {
      background-image: url("/container/form/images/pagettl-pic.png");
      background-repeat: no-repeat;
      background-position: right 20px bottom;
      @include sp {
        background-size: 140px auto;
        background-position: right 0 bottom;
      }
    }
  }
}

//メインビジュアル ※詳細は各ページごとのcss ex.).tech-mainvisual
.mainvisual {
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  &--pic {
    position: relative;
    @include sp {
      left:0;
      width: 100%;
      height: auto;
    }
  }
}

//カテゴリごとのインデックス
.category_index {
  position: relative;
  background-color: #fff;
  padding: 20px 0;
  @include sp {
    padding-bottom: 0;
  }
  &--inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 960px;
    margin: 0 auto;
    @include sp {
      display: block;
      width: 100%;
      min-height: auto;
    }
  }
  &--card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 470px;
    @include sp {
      justify-content: space-between;
      width: auto;
      margin: 0 15px 0 15px;
      padding: 0 0 20px 0;
    }
  }
  &--pic {
    width: 190px;
    @include sp {
      width: 26%;
    }
  }
  &--contents {
    width: 270px;
    @include sp {
      width: 72%;
    }
  }
  &--ttl {
    position: relative;
    margin: 0 0 5px 0;
    padding: 0 0 5px 0;
    border-bottom: 1px solid #e8e3df;
  }
  &--ttl_link {
    position: relative;
    display: block;
    @include fz(16);
    text-decoration: none;
    color: $colorBrown;
    font-weight: 700;
    padding: 10px 5px 10px 20px;
    margin: -5px;
    @include hover-pop;
    &:after {
      content: "";
      position: absolute;
      top:calc(50% - 3px);
      width: 5px;
      height: 5px;
      border-top: 2px solid $colorBrown;
      border-right: 2px solid $colorBrown;
      left:7px;
      transform: rotate(45deg);
    }
    @include sp {
      @include fz(14);
    }
    &.current {
      &:before {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
  }
  &--link {
    position: relative;
    display: block;
    @include fz(14);
    line-height: 1.3;
    letter-spacing: -1px;
    text-decoration: none;
    color: $colorBrown;
    padding: 8px 8px 8px 20px;
    margin: -5px;
    @include hover-pop;
    &:after {
      content: "";
      position: absolute;
      top:calc(50% - 4px);
      left:10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4.5px 0 4.5px 5px;
      border-color: transparent transparent transparent #603813;
    }
    @include sp {
      @include fz(13);
    }
    &.current {
      &:before {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
  }
}

//ページ送り
.pager {
  position: relative;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include sp {
    position: relative;
    padding: 0 15px 30px 15px;
  }
  &--link_side {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 26px;
    box-sizing: border-box;
    border-radius: 5px;
    @include fz(14);
    color: #fff;
    text-decoration: none;
    background-color: $colorBrown;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      margin: auto 0;
    }
    &:before {
      width: 1px;
      height: 16px;
      background-color: #fff;
    }
    &:after {
      width: 0;
      height: 0;
      border-style: solid;
    }
    &-first {
      padding: 0 0 0 10px;
      &:before {
        left:5px;
      }
      &:after {
        left:8px;
        border-width: 4px 7px 4px 0;
        border-color: transparent #ffffff transparent transparent;
      }
    }
    &-last {
      padding: 0 10px 0 0;
      &:before {
        right:5px;
      }
      &:after {
        right:8px;
        border-width: 4px 0 4px 7px;
        border-color: transparent transparent transparent #ffffff;
      }
    }
  }
  &--pager_link {
    @include fz(16);
    color: $colorBrown;
    padding: 0 10px;
  }
  &--pager_this {
    @include fz(16);
    color: $colorBrown;
    padding: 0 10px;
  }

}

//検索ボタン
.search_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 44px;
  border-radius: 22px;
  padding: 0 10px 0 0;
  box-sizing: border-box;
  background-color: $colorBrown;
  @include fz(16);
  color: #fff;
  box-shadow: 0 0 3px 0 rgba(0,0,0,.3);
  border: 0;
  cursor: pointer;
  transition: .2s;
  &:before {
    position: absolute;
    top: calc(50% - 9px);
    right:20px;
    @include icon2;
    content: $icon-search;
    @include fz(18);
  } 
  &:hover {
    background-color: darken($colorBrown, 5%);
    box-shadow: 0 0 0 #42210B;
  }
  @include sp {
    @include fz(10);
    width: 60px;
    padding: 25px 0 0 0;
    border-radius: 3px;
    &:before {
      top:7px;
      right:auto;
      left:auto;
    }
  }
}

.m_form-input {
  background-color: rgba(213, 203, 192, 0.3);
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  padding: 15px 15px;
  border: 1px solid transparent;
  border-radius: 10px;
  appearance: none;
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    border-color: rgba(117, 76, 36, 0.6);
  }
  &.error {
    border-color: #F80000;
    background-color: rgba(248, 0, 0, 0.05);
  }
  &-postcode {
    max-width: 150px;
  }
  &-tel {
    max-width: 260px;
  }
  @include sp {
    font-size: 16px;
    &-postcode {
      max-width: initial;
    }
    &-tel {
      max-width: initial;
    }
  }
}
.m_form-textarea {
  box-sizing: border-box;
  border: 1px solid transparent;
  background-color: rgba(213, 203, 192, 0.3);
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  width: 100%;
  padding: 14px;
  min-height: 240px;
  resize: none;
  font-family: inherit;
  &::placeholder {
    color: #8d8d8d;
  }
  &:focus {
    border-color: #7C7C7C;
  }
  @include sp {
    font-size: 16px;
  }
}
.m_form-checkbox {
  margin: 0;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #D5CBC0;
  background-color: #FFFFFF;
  position: relative;
  &:checked {
    background-color: #FFCC00;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211.667%22%20height%3D%229.288%22%20viewBox%3D%220%200%2011.667%209.288%22%3E%20%3Cpath%20id%3D%22check%22%20d%3D%22M13.916%2C5.066a1.071%2C1.071%2C0%2C1%2C1%2C1.529%2C1.5l-5.7%2C7.125A1.071%2C1.071%2C0%2C0%2C1%2C8.2%2C13.72L4.426%2C9.941A1.071%2C1.071%2C0%2C1%2C1%2C5.94%2C8.428l2.99%2C2.989L13.889%2C5.1a.337.337%2C0%2C0%2C1%2C.029-.031Z%22%20transform%3D%22translate%28-4.085%20-4.745%29%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-size: 12px 9px;
    background-position: center center;
    border: none;
  }
  @include sp {
    width: 20px;
    height: 20px;
  }

  &.error {
    border-color: #F80000;
    background-color: rgba(248, 0, 0, 0.05);
  }
}
.m_form-radio {
  appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #D5CBC0;
  background-color: #fff;
  position: relative;
  &:checked {
    background-color: #ffcc00;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228%22%20height%3D%228%22%20viewBox%3D%220%200%208%208%22%3E%20%3Ccircle%20id%3D%22%E6%A5%95%E5%86%86%E5%BD%A2_31%22%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%2031%22%20cx%3D%224%22%20cy%3D%224%22%20r%3D%224%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-size: 8px 8px;
    background-position: center center;
    border: none;
  }
  @include sp {
  }

  &.error {
    border-color: #F80000;
    background-color: rgba(248, 0, 0, 0.05);
  }
}
.m_form-select {
  color: #000;
  width: 100%;
  display: inline-block;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  padding: 15px 33px 15px 20px;
  border: none;
  border-radius: 9999px;
  background-color: rgba(213, 203, 192, 0.3);
  appearance: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%229.333%22%20viewBox%3D%220%200%2014%209.333%22%3E%20%3Cpath%20id%3D%22%E5%A4%9A%E8%A7%92%E5%BD%A2_68%22%20data-name%3D%22%E5%A4%9A%E8%A7%92%E5%BD%A2%2068%22%20d%3D%22M6.2%2C1.067a1%2C1%2C0%2C0%2C1%2C1.6%2C0l5%2C6.667a1%2C1%2C0%2C0%2C1-.8%2C1.6H2a1%2C1%2C0%2C0%2C1-.8-1.6Z%22%20transform%3D%22translate%2814%209.333%29%20rotate%28180%29%22%20fill%3D%22%238d8d8d%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 20px center;
  &:has(option:checked[value=""]) {
    color: #8d8d8d;
  }
  &-prefecture {
    max-width: 180px;

    &.error {
      border: 1px solid #F80000;
      background-color: rgba(248, 0, 0, 0.05);
    }
  }
  @include sp {
    font-size: 16px;
    &-prefecture {
      max-width: initial;
    }
  }
}
.m_form-btn {
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  border: none;
  display: block;
  width: 100%;
  max-width: 320px;
  border-radius: 9999px;
  background-color: #FF8123;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  padding-top: 19px;
  padding-bottom: 21px;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.39%22%20height%3D%229.951%22%20viewBox%3D%220%200%206.39%209.951%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_147%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20147%22%20transform%3D%22translate%280.707%200.707%29%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_43%22%20data-name%3D%22%E3%83%91%E3%82%B9%2043%22%20d%3D%22M112.707%2C3609l4.268%2C4.268-4.268%2C4.269%22%20transform%3D%22translate%28-112.707%20-3609%29%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 26px center;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
  &-gray {
    background-color: #cdd2de;
  }
}
.m_form-error {
  display: block;
  color: #ff0000;
  font-size: 13px;
  line-height: 1.5;
  margin-top: 3px;
  position: absolute;
  bottom: -20px;
  left: 0;

  &#personal_info-error {
    left: 50%;
    transform: translateX(-50%);
  }
}