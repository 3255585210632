@charset "UTF-8";
.release {
  font-size: 12px;
  font-size: 0.75rem;
  margin: 0 0 5px 0;
  display: block;
}

.release-right {
  margin: 0 0 5px auto;
  text-align: right;
}

.release-recipesearch {
  margin: 0 0 10px auto;
  text-align: right;
}

@media (max-width: 425px) {
  .release-recipesearch {
    margin: 0 10px 10px auto;
  }
}

.release--icon {
  margin: 0 0 0 5px;
  position: relative;
  top: 2px;
  display: inline-block;
}

.new_release_list {
  list-style: none;
  width: 690px;
  margin: 0 0 30px 0;
}

@media (max-width: 425px) {
  .new_release_list {
    width: 90%;
    margin: 0 auto 30px auto;
  }
}

.new_release_list-backnumber {
  width: auto;
}

.new_release_list-backnumber .new_release_list--item {
  border-bottom-color: #ccc;
}

.new_release_list-backnumber .new_release_list--description {
  width: calc(100% - 100px);
}

@media (max-width: 425px) {
  .new_release_list-backnumber .new_release_list--description {
    width: auto;
  }
}

@media (max-width: 425px) {
  .new_release_list-backnumber {
    width: 90%;
    margin: 0 auto 30px auto;
  }
}

.new_release_list-result .new_release_list--item {
  border-bottom: none;
  margin-bottom: 30px;
}

@media (max-width: 425px) {
  .new_release_list-result {
    width: auto;
  }
}

.new_release_list.box-fadeout {
  position: relative;
}

.new_release_list.box-fadeout:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+50,ffffff+90&0+0,1+50 */
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 50%, white 90%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 50%, white 90%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 50%, white 90%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */
}

.new_release_list--item {
  border-bottom: 2px dashed #fff;
  padding: 15px 0 10px 0;
  opacity: 1;
  height: auto;
  margin: auto;
  transition: .3s;
}

.new_release_list--item.is-hidden {
  opacity: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

.new_release_list--more {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 222px;
  height: 40px;
  border-radius: 20px;
  padding: 0 10px 0 0;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #ffcc00;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #603813;
  font-weight: 700;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  border: 0;
  cursor: pointer;
  transition: .2s;
}

.new_release_list--more:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto 0;
  width: 5px;
  height: 5px;
  border-top: 1px solid #603813;
  border-right: 1px solid #603813;
  transform: rotate(135deg);
}

.new_release_list--more:hover {
  background-color: #ff720a;
  box-shadow: 0 0 0 #42210B;
}

@media (max-width: 425px) {
  .new_release_list--more {
    margin: -30px auto 30px auto;
  }
}

.new_release_list--more.is-btn-hidden {
  display: none;
}

.new_release_list--link {
  position: relative;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #000;
}

.new_release_list--link:after {
  position: absolute;
  top: -8px;
  left: -8px;
  opacity: 0;
  background-color: #c89b05;
  content: "";
  display: block;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  border-radius: 5px;
  transition: .15s;
  transform: scale(0.9, 0.9);
}

.new_release_list--link:hover:after {
  opacity: .3;
  transform: scale(1, 1);
}

@media (max-width: 425px) {
  .new_release_list--link {
    display: block;
  }
  .new_release_list--link .release {
    display: block;
    margin: 0 0 5px 0;
  }
}

.new_release_list--nolink {
  position: relative;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #000;
}

.new_release_list--release {
  font-size: 12px;
  font-size: 0.75rem;
  color: #505050;
  margin: 5px 0;
  display: block;
}

.new_release_list--description {
  width: 580px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
}

@media (max-width: 425px) {
  .new_release_list--description {
    width: auto;
  }
}

.cmn_recipe_counter--ttl {
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 0 5px 0;
}

@media (max-width: 425px) {
  .cmn_recipe_counter--ttl {
    font-size: 10px;
    font-size: 0.625rem;
    margin-bottom: 0;
  }
}

.cmn_recipe_counter--link {
  position: relative;
  display: block;
  text-decoration: none;
  color: #42210B;
}

.cmn_recipe_counter--link:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: -22px;
  width: 16px;
  height: 22px;
  border-radius: 5px;
  background-color: transparent;
  transition: .15s;
}

.cmn_recipe_counter--link:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: -20px;
  width: 6px;
  height: 6px;
  border-top: 2px solid #42210B;
  border-right: 2px solid #42210B;
  transform: rotate(45deg);
}

.cmn_recipe_counter--link:hover:before {
  background-color: #ffcc00;
}

@media (max-width: 425px) {
  .cmn_recipe_counter--link:after {
    left: -10px;
    width: 4px;
    height: 4px;
  }
  .cmn_recipe_counter--link:hover:before {
    content: none;
  }
}

.cmn_recipe_counter--num {
  display: inline-block;
  font-size: 34px;
  font-size: 2.125rem;
  font-weight: 700;
}

@media (max-width: 425px) {
  .cmn_recipe_counter--num {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

.cmn_recipe_counter--unit {
  display: inline-block;
}

@media (max-width: 425px) {
  .cmn_recipe_counter--unit {
    width: 45px;
  }
}

.searchbox {
  position: relative;
  display: flex;
  align-items: center;
}

@media (max-width: 425px) {
  .searchbox {
    justify-content: flex-end;
    margin: 0 10px;
    width: 100%;
    box-sizing: border-box;
  }
}

.searchbox:before {
  position: absolute;
  top: 4px;
  right: 5px;
  background-color: #f6883f;
  content: "";
  width: 28px;
  border: 0;
  -webkit-appearance: none;
  height: 28px;
  border-radius: 50%;
}

.searchbox:after {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-size: 0.875rem;
  position: absolute;
  top: 11px;
  right: 11px;
  content: "꤆";
  color: #fff;
}

.searchbox--search_tag {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0 15px;
  background-color: #eeeae6;
  -webkit-appearance: none;
  border-radius: 30px;
  border: 0;
  min-width: 300px;
  box-sizing: border-box;
  color: #000;
  height: 36px;
  transition: .5s;
}

@media (max-width: 425px) {
  .searchbox--search_tag {
    font-size: 16px;
    font-size: 1rem;
    width: 225px;
    min-width: 225px;
  }
  .searchbox--search_tag:focus {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) inset;
    width: 100%;
  }
}

.searchbox--submit_tag {
  position: absolute;
  top: 4px;
  right: 5px;
  background-color: transparent;
  width: 28px;
  border: 0;
  -webkit-appearance: none;
  height: 28px;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
}

.bnrbox-forsp {
  display: none;
}

@media (max-width: 425px) {
  .bnrbox-forsp {
    position: relative;
    overflow: hidden;
    display: block;
    padding: 20px 0 10px 0;
  }
}

.bnrbox-forpc {
  display: block;
  padding: 30px 0;
}

@media (max-width: 425px) {
  .bnrbox-forpc {
    padding: 20px 0 10px 0;
  }
}

.bnrbox--inner {
  position: relative;
  width: 750px;
  margin: 0 auto;
  overflow: hidden;
}

@media (max-width: 425px) {
  .bnrbox--inner {
    width: 100%;
    overflow: hidden;
  }
}

.bnrbox--link {
  position: relative;
  display: block;
  width: 240px;
  margin-right: 10px;
  line-height: 0;
  border: 1px solid #ccc;
  transition: .15s;
}

.bnrbox--link:hover {
  border: 1px solid #603813;
  filter: brightness(105%);
}

@media (max-width: 425px) {
  .bnrbox--link {
    width: 48%;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}

.bnrbox--img {
  width: 100%;
}

.bnrbox .flickity-page-dots {
  position: relative;
  width: 750px;
  margin: 10px auto 0 auto;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

@media (max-width: 425px) {
  .bnrbox .flickity-page-dots {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }
}

.bnrbox .dot {
  display: block;
  border: 2px solid #603813;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-sizing: border-box;
  margin: 0 10px 0 0;
  transition: .15s;
}

.bnrbox .dot:hover, .bnrbox .dot.is-selected {
  background-color: #facd39;
}

@media (max-width: 425px) {
  .bnrbox .dot {
    width: 10px;
    height: 10px;
  }
}

.bnrbox .flickity-button {
  display: none;
}

@media (max-width: 425px) {
  .recipe_contents_condition-index .recipe_contents_condition--submit_area {
    width: 100%;
    height: 75px;
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
    background-color: #42210B;
  }
}

@media (max-width: 425px) {
  .recipe_contents_condition-list {
    padding-bottom: 70px;
    box-sizing: border-box;
  }
  .recipe_contents_condition-list .recipe_contents_condition--fixedarea {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #42210B;
  }
}

.recipe_contents_condition--inner {
  position: relative;
  background-color: #fff;
  padding: 10px 0;
}

@media (max-width: 425px) {
  .recipe_contents_condition--inner {
    padding: 0;
  }
}

.recipe_contents_condition--intro {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.4;
  text-align: center;
  color: #603813;
  padding: 0 0 20px 0;
}

@media (max-width: 425px) {
  .recipe_contents_condition--intro {
    display: none;
  }
}

.recipe_contents_condition--col {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #efebe8;
  width: 895px;
  margin: 0 auto;
  padding: 5px 0 10px 0;
}

@media (max-width: 425px) {
  .recipe_contents_condition--col {
    display: block;
    width: 100%;
    padding: 0;
  }
}

.recipe_contents_condition--l {
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  color: #42210B;
  width: 180px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 425px) {
  .recipe_contents_condition--l {
    width: auto;
    background-image: url("/container/common/images/bg-main-content.png");
    padding: 12px 15px;
    border-bottom: 1px solid #fff;
    transition: .15s;
  }
}

.recipe_contents_condition--l-toggle {
  pointer-events: none;
}

@media (max-width: 425px) {
  .recipe_contents_condition--l-toggle {
    pointer-events: auto;
  }
  .recipe_contents_condition--l-toggle:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto 0;
    width: 7px;
    height: 7px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    transform: rotate(135deg);
    transition: .15s;
  }
  .recipe_contents_condition--l-toggle.active:after {
    transform: rotate(-45deg);
  }
}

.recipe_contents_condition--r {
  width: 715px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (max-width: 425px) {
  .recipe_contents_condition--r {
    display: none;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    transform: .3s;
  }
}

.recipe_contents_condition--r-open {
  display: block;
}

@media (max-width: 425px) {
  .recipe_contents_condition--r_inner {
    padding: 10px;
  }
}

.recipe_contents_condition--package {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 425px) {
  .recipe_contents_condition--package {
    align-items: stretch;
  }
  .recipe_contents_condition--package-box1 .recipe_contents_condition--label:last-child, .recipe_contents_condition--package-box1 .recipe_contents_condition--label:nth-last-child(2), .recipe_contents_condition--package-box2 .recipe_contents_condition--label:last-child, .recipe_contents_condition--package-box2 .recipe_contents_condition--label:nth-last-child(2) {
    border-bottom: none;
  }
  .recipe_contents_condition--package-box3 .recipe_contents_condition--label:last-child, .recipe_contents_condition--package-box4 .recipe_contents_condition--label:last-child {
    border-bottom: none;
  }
}

.recipe_contents_condition--2cols {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px 10px 0;
}

@media (max-width: 425px) {
  .recipe_contents_condition--2cols {
    padding-left: 10px;
  }
}

.recipe_contents_condition--freeword {
  position: relative;
}

.recipe_contents_condition--input_freeword {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0 15px;
  background-color: #eeeae6;
  -webkit-appearance: none;
  border-radius: 30px;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  color: #000;
  height: 46px;
  transition: .5s;
}

.recipe_contents_condition--input_freeword::placeholder {
  color: #949494;
}

.recipe_contents_condition--input_freeword:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) inset;
  min-width: 305px;
}

@media (max-width: 425px) {
  .recipe_contents_condition--input_freeword {
    font-size: 16px;
    font-size: 1rem;
  }
}

.recipe_contents_condition--description {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.2;
  color: #b3b3b3;
  padding: 10px 0 0 0;
}

.recipe_contents_condition--label {
  margin: 0 15px 5px 0;
  padding: 0;
  color: #505050;
  cursor: pointer;
  transition: .15s;
}

.recipe_contents_condition--label:hover {
  color: #cca300;
}

@media (max-width: 425px) {
  .recipe_contents_condition--label {
    width: 33%;
    box-sizing: border-box;
    padding: 10px 2px;
    margin: 0;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.2;
    color: #505050;
    cursor: pointer;
    transition: .15s;
  }
  .recipe_contents_condition--label:nth-child(3n) {
    border-right: none;
  }
}

.recipe_contents_condition--radiobox {
  position: relative;
  top: 2px;
  border: 1px solid #505050;
  appearance: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transition: .15s;
}

.recipe_contents_condition--radiobox:after {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #cca300;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0, 0);
}

.recipe_contents_condition--radiobox:checked {
  border-radius: 50%;
}

.recipe_contents_condition--radiobox:checked:after {
  animation: inputFade 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

@keyframes inputFade {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

.recipe_contents_condition--checkbox {
  position: relative;
  top: 5px;
  border: 1px solid #505050;
  appearance: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  transition: .15s;
}

.recipe_contents_condition--checkbox:after {
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #cca300;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  transform: scale(0, 0);
}

.recipe_contents_condition--checkbox:checked:after {
  animation: inputFade 0.2s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}

@keyframes inputFade {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

@media (max-width: 425px) {
  .recipe_contents_condition--checkbox {
    top: 0;
  }
}

.recipe_contents_condition--select_box {
  margin: 5px 3px 0 0;
  position: relative;
  display: inline-block;
  top: 0;
  background-color: #eeeae6;
  height: 34px;
  width: 190px;
  overflow: hidden;
  font-size: 14px;
  font-size: 0.875rem;
}

.recipe_contents_condition--select_box:before {
  position: absolute;
  top: 8px;
  right: 10px;
  content: "";
  display: block;
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent #725c50 transparent;
}

.recipe_contents_condition--select_box:after {
  position: absolute;
  right: 10px;
  bottom: 8px;
  content: "";
  display: block;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #725c50 transparent transparent transparent;
}

.recipe_contents_condition--select_box:nth-child(3n) {
  margin-right: 0;
}

@media (max-width: 425px) {
  .recipe_contents_condition--select_box {
    width: calc(50% - 5px);
    margin: 0 10px 10px 0;
    height: 44px;
  }
  .recipe_contents_condition--select_box:nth-child(3n) {
    margin-right: auto;
  }
  .recipe_contents_condition--select_box:nth-child(2n) {
    margin-right: 0;
  }
}

.recipe_contents_condition--select_box-wide {
  width: 290px;
}

@media (max-width: 425px) {
  .recipe_contents_condition--select_box-wide {
    width: 100%;
  }
}

.recipe_contents_condition--select_box-sp {
  display: none;
}

@media (max-width: 425px) {
  .recipe_contents_condition--select_box-sp {
    display: block;
    width: 100%;
  }
}

.recipe_contents_condition--select {
  appearance: none;
  background-color: transparent;
  font-size: 14px;
  font-size: 0.875rem;
  padding: 5px 10px;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  height: 34px;
  overflow: hidden;
}

@media (max-width: 425px) {
  .recipe_contents_condition--select {
    height: 44px;
  }
}

.recipe_contents_condition--input_box {
  display: inline-block;
}

.recipe_contents_condition--input {
  position: relative;
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  margin: 5px 10px 0 0;
  padding: 5px 10px;
  background-color: #eeeae6;
  width: 190px;
  border: 0;
  height: 34px;
  box-sizing: border-box;
  z-index: 100;
}

@media (max-width: 425px) {
  .recipe_contents_condition--input {
    margin: 0 0 10px 0;
    width: calc(50% - 10px);
    height: 44px;
    border: 0;
  }
}

.recipe_contents_condition--type {
  position: relative;
  top: 5px;
  display: inline-block;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
}

@media (max-width: 425px) {
  .recipe_contents_condition--type {
    top: 15px;
    font-size: 16px;
    font-size: 1rem;
  }
}

.recipe_contents_condition--txt {
  display: inline;
  margin: 0 0 0 10px;
  position: relative;
  top: 4px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
}

@media (max-width: 425px) {
  .recipe_contents_condition--txt {
    font-size: 12px;
    font-size: 0.75rem;
    display: inline-block;
    width: 40%;
    top: 10px;
  }
}

.recipe_contents_condition--submit_box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffcc00;
  width: 400px;
  height: 60px;
  margin: 15px auto;
  border-radius: 30px;
  transition: .15s;
}

.recipe_contents_condition--submit_box:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  width: 7px;
  height: 7px;
  margin: auto 0;
  border-top: 2px solid #603813;
  border-right: 2px solid #603813;
  transform: rotate(45deg);
  pointer-events: none;
  transition: .15s;
}

.recipe_contents_condition--submit_box:hover {
  filter: contrast(120%);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
}

@media (max-width: 425px) {
  .recipe_contents_condition--submit_box {
    width: 90%;
    height: 50px;
    border-radius: 25px;
    margin: auto;
  }
}

.recipe_contents_condition--submit {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  background-color: transparent;
  width: 100%;
  border: 0;
  color: #603813;
  appearance: none;
  height: 60px;
  cursor: pointer;
  transition: .15s;
}

@media (max-width: 425px) {
  .recipe_contents_condition--submit {
    height: 50px;
  }
}

.ranking {
  position: relative;
  background-image: url("/container/common/images/cmn-bg-stripe.png");
}

.ranking--inner {
  width: 800px;
  margin: 0 auto;
  padding: 40px 0 80px 0;
}

@media (max-width: 425px) {
  .ranking--inner {
    width: 100%;
    padding-bottom: 120px;
  }
}

.ranking--ttl {
  background-image: url("/container/common/images/ranking-ttl.png");
  background-repeat: no-repeat;
  background-position: top center;
  padding: 80px 0 40px 0;
  text-align: center;
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: 700;
  color: #42210B;
}

.ranking--wrap {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.ranking--wrap:after {
  content: "";
  width: 120px;
  height: 80px;
  background-image: url("/container/common/images/charactor01.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: -20px;
  right: -100px;
}

@media (max-width: 425px) {
  .ranking--wrap {
    display: block;
  }
  .ranking--wrap:after {
    bottom: -100px;
    right: 35%;
  }
}

.ranking--content {
  position: relative;
  width: 370px;
  background-color: #fff;
  border-top: 3px solid #603813;
}

.ranking--content:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 205px;
  height: 40px;
  background-image: url("/container/common/images/ranking-ribbon.png");
  background-repeat: no-repeat;
  background-position: top center;
  z-index: 5;
}

@media (max-width: 425px) {
  .ranking--content {
    position: static;
    width: 90%;
    min-width: 320px;
    margin: 0 auto;
    line-height: 0;
  }
  .ranking--content-general {
    border-top: none;
  }
}

@media (max-width: 425px) {
  .ranking--content_tab {
    position: absolute;
    top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 45%;
    height: 95px;
    background-color: #ede9d9;
  }
  .ranking--content_tab.active {
    width: 45%;
    background-color: #fff;
    box-shadow: 0 0 0 0;
  }
  .ranking--content_tab-newstock {
    left: 5%;
  }
  .ranking--content_tab-total {
    right: 5%;
  }
}

.ranking--ttl2 {
  padding: 40px 20px;
  font-size: 18px;
  font-size: 1.125rem;
  color: #603813;
}

@media (max-width: 425px) {
  .ranking--ttl2 {
    padding: 15px 0;
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (max-width: 425px) {
  .ranking--ttl2_delete {
    display: none;
  }
}

.ranking--release {
  position: absolute;
  top: 42px;
  right: 20px;
}

@media (max-width: 425px) {
  .ranking--release {
    position: relative;
    top: auto;
    right: auto;
  }
}

.ranking--list {
  list-style: none;
  padding: 0 10px;
}

@media (max-width: 425px) {
  .ranking--list {
    margin: 95px 0 0 0;
  }
}

@media (max-width: 425px) {
  .ranking--list-newstock {
    display: none;
  }
  .ranking--list-newstock.current {
    display: block;
  }
}

@media (max-width: 425px) {
  .ranking--list-total {
    display: none;
  }
  .ranking--list-total.current {
    display: block;
  }
}

.ranking--item {
  position: relative;
  border-top: 1px dotted #ddd5cd;
  box-sizing: border-box;
}

.ranking--pic {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto 0;
  height: 80px;
}

.ranking--link {
  position: relative;
  display: flex;
  align-items: center;
  height: 110px;
  padding: 0 0 0 140px;
  color: #000;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.3;
  text-decoration: none;
}

.ranking--link:after {
  position: absolute;
  top: 5px;
  left: -5px;
  opacity: 0;
  background-color: #c89b05;
  content: "";
  display: block;
  width: calc(100% + 10px);
  height: calc(100% - 10px);
  border-radius: 5px;
  transition: .15s;
  transform: scale(0.9, 0.9);
}

.ranking--link:hover:after {
  opacity: .3;
  transform: scale(1, 1);
}

.ranking--rank {
  display: block;
  position: absolute;
  top: auto;
  bottom: auto;
  left: 110px;
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
  z-index: 1;
}

.ranking--rank:before {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "꤁";
  font-size: 38px;
  font-size: 2.375rem;
  position: absolute;
  top: -14px;
  left: -14px;
  z-index: -1;
}

.ranking--rank-gold:before {
  color: #d7a447;
}

.ranking--rank-blue:before {
  color: #607b97;
}

.ranking--rank-copper:before {
  color: #9e5331;
}

.ranking--rank-grey:before {
  color: #b2b2b2;
}

.recommend {
  position: relative;
  overflow: hidden;
  padding: 50px 0 100px 0;
  margin: 0 0 10px 0;
  background-color: #fff;
}

.recommend:after {
  content: "";
  width: 170px;
  height: 135px;
  background-image: url("/container/common/images/charactor02.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: calc(50% - 480px);
}

@media (max-width: 425px) {
  .recommend:after {
    left: 20%;
  }
}

.recommend--inner {
  position: relative;
  width: 960px;
  margin: 0 auto;
}

@media (max-width: 425px) {
  .recommend--inner {
    width: 100%;
  }
}

.recommend--release {
  position: absolute;
  top: 90px;
  right: 40px;
}

@media (max-width: 425px) {
  .recommend--release {
    top: auto;
    bottom: 5px;
    right: 15px;
    left: 0;
    text-align: right;
  }
}

.recommend--slider {
  position: relative;
  margin: 0 0 40px 0;
}

.recommend--slider_inner {
  position: relative;
  width: 100%;
}

.recommend--cover {
  display: block;
  width: 20%;
  height: 100%;
  position: absolute;
  top: 0;
  pointer-events: none;
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  /* IE6-9 */
}

.recommend--cover-r {
  right: 0;
  transform: rotate(180deg);
}

.recommend--cover-l {
  left: 0;
}

.recommend--item {
  position: relative;
  display: block;
  overflow: hidden;
  width: 220px;
  color: #603813;
  text-decoration: none;
  padding: 10px;
  box-sizing: border-box;
  transition: .15s;
}

.recommend--item:hover {
  background-color: rgba(200, 155, 5, 0.3);
  border-radius: 10px;
}

.recommend--pic {
  display: block;
  width: 200px;
  margin: 0 0 10px 0;
}

.recommend--name {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.3;
  color: #603813;
  min-height: 50px;
  margin: 0 0 10px 0;
}

.recommend--description {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.recommend--arw {
  position: absolute;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #603813;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  transition: .3s;
}

.recommend--arw:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 10px;
  height: 10px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
}

.recommend--arw-l {
  left: 10%;
}

.recommend--arw-l:before {
  left: 20px;
  transform: rotate(-135deg);
}

.recommend--arw-l:hover {
  left: 9%;
}

@media (max-width: 425px) {
  .recommend--arw-l {
    left: 3%;
  }
  .recommend--arw-l:hover {
    left: 2%;
  }
}

.recommend--arw-r {
  right: 10%;
}

.recommend--arw-r:before {
  right: 20px;
  transform: rotate(45deg);
}

.recommend--arw-r:hover {
  right: 9%;
}

@media (max-width: 425px) {
  .recommend--arw-r {
    right: 3%;
  }
  .recommend--arw-r:hover {
    right: 2%;
  }
}

.cmn_ttl {
  background-image: url("/container/common/images/ranking-ttl.png");
  background-repeat: no-repeat;
  background-position: top center;
  padding: 80px 0 40px 0;
  text-align: center;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #42210B;
}

@media (max-width: 425px) {
  .cmn_ttl {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.passage_ttl {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  color: #fff;
  background-image: url("/container/recipesearch/images/recipe-bg-bar.png");
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 0;
  margin: 50px 0 20px 0;
}

@media (max-width: 425px) {
  .passage_ttl {
    font-size: 16px;
    font-size: 1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 20px 0;
    margin-top: 30px;
  }
}

.pagettl_box {
  position: relative;
  width: 100%;
  background-image: url("/container/common/images/bg-pagettl.png");
}

.pagettl_box--txt {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 960px;
  height: 220px;
  margin: 0 auto;
  font-size: 28px;
  font-size: 1.75rem;
  color: #603813;
}

.pagettl_box--txt:before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 20px;
  background-size: cover;
}

@media (max-width: 425px) {
  .pagettl_box--txt {
    width: 100%;
    height: 140px;
    font-size: 16px;
    font-size: 1rem;
    padding-left: 15px;
    box-sizing: border-box;
  }
}

.pagettl_box--txt-faq:before {
  background-image: url("/container/faq/images/pagettl-pic.png");
  background-repeat: no-repeat;
  background-position: center center;
  width: 260px;
  height: 165px;
}

@media (max-width: 425px) {
  .pagettl_box--txt-faq:before {
    width: 156px;
    height: 99px;
  }
}

.pagettl_box--txt-company:before {
  background-image: url("/container/company/images/pagettl-pic.png");
  background-repeat: no-repeat;
  background-position: center center;
  width: 260px;
  height: 140px;
}

@media (max-width: 425px) {
  .pagettl_box--txt-company:before {
    width: 156px;
    height: 84px;
  }
}

.pagettl_box--txt-contact:before {
  background-image: url("/container/contact/images/pagettl-pic.png");
  background-repeat: no-repeat;
  background-position: center center;
  width: 260px;
  height: 150px;
}

@media (max-width: 425px) {
  .pagettl_box--txt-contact:before {
    width: 200px;
    height: 115px;
  }
}

.pagettl_box--txt-form {
  background-image: url("/container/form/images/pagettl-pic.png");
  background-repeat: no-repeat;
  background-position: right 20px bottom;
}

@media (max-width: 425px) {
  .pagettl_box--txt-form {
    background-size: 140px auto;
    background-position: right 0 bottom;
  }
}

.mainvisual {
  position: relative;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.mainvisual--pic {
  position: relative;
}

@media (max-width: 425px) {
  .mainvisual--pic {
    left: 0;
    width: 100%;
    height: auto;
  }
}

.category_index {
  position: relative;
  background-color: #fff;
  padding: 20px 0;
}

@media (max-width: 425px) {
  .category_index {
    padding-bottom: 0;
  }
}

.category_index--inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 960px;
  margin: 0 auto;
}

@media (max-width: 425px) {
  .category_index--inner {
    display: block;
    width: 100%;
    min-height: auto;
  }
}

.category_index--card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 470px;
}

@media (max-width: 425px) {
  .category_index--card {
    justify-content: space-between;
    width: auto;
    margin: 0 15px 0 15px;
    padding: 0 0 20px 0;
  }
}

.category_index--pic {
  width: 190px;
}

@media (max-width: 425px) {
  .category_index--pic {
    width: 26%;
  }
}

.category_index--contents {
  width: 270px;
}

@media (max-width: 425px) {
  .category_index--contents {
    width: 72%;
  }
}

.category_index--ttl {
  position: relative;
  margin: 0 0 5px 0;
  padding: 0 0 5px 0;
  border-bottom: 1px solid #e8e3df;
}

.category_index--ttl_link {
  position: relative;
  display: block;
  font-size: 16px;
  font-size: 1rem;
  text-decoration: none;
  color: #603813;
  font-weight: 700;
  padding: 10px 5px 10px 20px;
  margin: -5px;
}

.category_index--ttl_link:before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-color: rgba(200, 155, 5, 0.3);
  border-radius: 5px;
  opacity: 0;
  transform: scale(0.9, 0.9);
  transition: .15s;
}

.category_index--ttl_link:hover:before {
  opacity: 1;
  transform: scale(1, 1);
}

.category_index--ttl_link:after {
  content: "";
  position: absolute;
  top: calc(50% - 3px);
  width: 5px;
  height: 5px;
  border-top: 2px solid #603813;
  border-right: 2px solid #603813;
  left: 7px;
  transform: rotate(45deg);
}

@media (max-width: 425px) {
  .category_index--ttl_link {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.category_index--ttl_link.current:before {
  opacity: 1;
  transform: scale(1, 1);
}

.category_index--link {
  position: relative;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3;
  letter-spacing: -1px;
  text-decoration: none;
  color: #603813;
  padding: 8px 8px 8px 20px;
  margin: -5px;
}

.category_index--link:before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-color: rgba(200, 155, 5, 0.3);
  border-radius: 5px;
  opacity: 0;
  transform: scale(0.9, 0.9);
  transition: .15s;
}

.category_index--link:hover:before {
  opacity: 1;
  transform: scale(1, 1);
}

.category_index--link:after {
  content: "";
  position: absolute;
  top: calc(50% - 4px);
  left: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4.5px 0 4.5px 5px;
  border-color: transparent transparent transparent #603813;
}

@media (max-width: 425px) {
  .category_index--link {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

.category_index--link.current:before {
  opacity: 1;
  transform: scale(1, 1);
}

.pager {
  position: relative;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 425px) {
  .pager {
    position: relative;
    padding: 0 15px 30px 15px;
  }
}

.pager--link_side {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 26px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  font-size: 0.875rem;
  color: #fff;
  text-decoration: none;
  background-color: #603813;
}

.pager--link_side:before, .pager--link_side:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.pager--link_side:before {
  width: 1px;
  height: 16px;
  background-color: #fff;
}

.pager--link_side:after {
  width: 0;
  height: 0;
  border-style: solid;
}

.pager--link_side-first {
  padding: 0 0 0 10px;
}

.pager--link_side-first:before {
  left: 5px;
}

.pager--link_side-first:after {
  left: 8px;
  border-width: 4px 7px 4px 0;
  border-color: transparent #ffffff transparent transparent;
}

.pager--link_side-last {
  padding: 0 10px 0 0;
}

.pager--link_side-last:before {
  right: 5px;
}

.pager--link_side-last:after {
  right: 8px;
  border-width: 4px 0 4px 7px;
  border-color: transparent transparent transparent #ffffff;
}

.pager--pager_link {
  font-size: 16px;
  font-size: 1rem;
  color: #603813;
  padding: 0 10px;
}

.pager--pager_this {
  font-size: 16px;
  font-size: 1rem;
  color: #603813;
  padding: 0 10px;
}

.search_btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 44px;
  border-radius: 22px;
  padding: 0 10px 0 0;
  box-sizing: border-box;
  background-color: #603813;
  font-size: 16px;
  font-size: 1rem;
  color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  border: 0;
  cursor: pointer;
  transition: .2s;
}

.search_btn:before {
  position: absolute;
  top: calc(50% - 9px);
  right: 20px;
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "꤆";
  font-size: 18px;
  font-size: 1.125rem;
}

.search_btn:hover {
  background-color: #4b2c0f;
  box-shadow: 0 0 0 #42210B;
}

@media (max-width: 425px) {
  .search_btn {
    font-size: 10px;
    font-size: 0.625rem;
    width: 60px;
    padding: 25px 0 0 0;
    border-radius: 3px;
  }
  .search_btn:before {
    top: 7px;
    right: auto;
    left: auto;
  }
}

.m_form-input {
  background-color: rgba(213, 203, 192, 0.3);
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  padding: 15px 15px;
  border: 1px solid transparent;
  border-radius: 10px;
  appearance: none;
  font-family: inherit;
  width: 100%;
  box-sizing: border-box;
}

.m_form-input:focus {
  border-color: rgba(117, 76, 36, 0.6);
}

.m_form-input.error {
  border-color: #F80000;
  background-color: rgba(248, 0, 0, 0.05);
}

.m_form-input-postcode {
  max-width: 150px;
}

.m_form-input-tel {
  max-width: 260px;
}

@media (max-width: 425px) {
  .m_form-input {
    font-size: 16px;
  }
  .m_form-input-postcode {
    max-width: initial;
  }
  .m_form-input-tel {
    max-width: initial;
  }
}

.m_form-textarea {
  box-sizing: border-box;
  border: 1px solid transparent;
  background-color: rgba(213, 203, 192, 0.3);
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.5;
  display: block;
  width: 100%;
  padding: 14px;
  min-height: 240px;
  resize: none;
  font-family: inherit;
}

.m_form-textarea::placeholder {
  color: #8d8d8d;
}

.m_form-textarea:focus {
  border-color: #7C7C7C;
}

@media (max-width: 425px) {
  .m_form-textarea {
    font-size: 16px;
  }
}

.m_form-checkbox {
  margin: 0;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #D5CBC0;
  background-color: #FFFFFF;
  position: relative;
}

.m_form-checkbox:checked {
  background-color: #FFCC00;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211.667%22%20height%3D%229.288%22%20viewBox%3D%220%200%2011.667%209.288%22%3E%20%3Cpath%20id%3D%22check%22%20d%3D%22M13.916%2C5.066a1.071%2C1.071%2C0%2C1%2C1%2C1.529%2C1.5l-5.7%2C7.125A1.071%2C1.071%2C0%2C0%2C1%2C8.2%2C13.72L4.426%2C9.941A1.071%2C1.071%2C0%2C1%2C1%2C5.94%2C8.428l2.99%2C2.989L13.889%2C5.1a.337.337%2C0%2C0%2C1%2C.029-.031Z%22%20transform%3D%22translate%28-4.085%20-4.745%29%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: 12px 9px;
  background-position: center center;
  border: none;
}

@media (max-width: 425px) {
  .m_form-checkbox {
    width: 20px;
    height: 20px;
  }
}

.m_form-checkbox.error {
  border-color: #F80000;
  background-color: rgba(248, 0, 0, 0.05);
}

.m_form-radio {
  appearance: none;
  margin: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #D5CBC0;
  background-color: #fff;
  position: relative;
}

.m_form-radio:checked {
  background-color: #ffcc00;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%228%22%20height%3D%228%22%20viewBox%3D%220%200%208%208%22%3E%20%3Ccircle%20id%3D%22%E6%A5%95%E5%86%86%E5%BD%A2_31%22%20data-name%3D%22%E6%A5%95%E5%86%86%E5%BD%A2%2031%22%20cx%3D%224%22%20cy%3D%224%22%20r%3D%224%22%20fill%3D%22%23fff%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: 8px 8px;
  background-position: center center;
  border: none;
}

.m_form-radio.error {
  border-color: #F80000;
  background-color: rgba(248, 0, 0, 0.05);
}

.m_form-select {
  color: #000;
  width: 100%;
  display: inline-block;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  padding: 15px 33px 15px 20px;
  border: none;
  border-radius: 9999px;
  background-color: rgba(213, 203, 192, 0.3);
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214%22%20height%3D%229.333%22%20viewBox%3D%220%200%2014%209.333%22%3E%20%3Cpath%20id%3D%22%E5%A4%9A%E8%A7%92%E5%BD%A2_68%22%20data-name%3D%22%E5%A4%9A%E8%A7%92%E5%BD%A2%2068%22%20d%3D%22M6.2%2C1.067a1%2C1%2C0%2C0%2C1%2C1.6%2C0l5%2C6.667a1%2C1%2C0%2C0%2C1-.8%2C1.6H2a1%2C1%2C0%2C0%2C1-.8-1.6Z%22%20transform%3D%22translate%2814%209.333%29%20rotate%28180%29%22%20fill%3D%22%238d8d8d%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 20px center;
}

.m_form-select:has(option:checked[value=""]) {
  color: #8d8d8d;
}

.m_form-select-prefecture {
  max-width: 180px;
}

.m_form-select-prefecture.error {
  border: 1px solid #F80000;
  background-color: rgba(248, 0, 0, 0.05);
}

@media (max-width: 425px) {
  .m_form-select {
    font-size: 16px;
  }
  .m_form-select-prefecture {
    max-width: initial;
  }
}

.m_form-btn {
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  border: none;
  display: block;
  width: 100%;
  max-width: 320px;
  border-radius: 9999px;
  background-color: #FF8123;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  padding-top: 19px;
  padding-bottom: 21px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.39%22%20height%3D%229.951%22%20viewBox%3D%220%200%206.39%209.951%22%3E%20%3Cg%20id%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_147%22%20data-name%3D%22%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%20147%22%20transform%3D%22translate%280.707%200.707%29%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_43%22%20data-name%3D%22%E3%83%91%E3%82%B9%2043%22%20d%3D%22M112.707%2C3609l4.268%2C4.268-4.268%2C4.269%22%20transform%3D%22translate%28-112.707%20-3609%29%22%20fill%3D%22none%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 26px center;
  transition: 0.2s;
}

.m_form-btn:hover {
  opacity: 0.8;
}

.m_form-btn-gray {
  background-color: #cdd2de;
}

.m_form-error {
  display: block;
  color: #ff0000;
  font-size: 13px;
  line-height: 1.5;
  margin-top: 3px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.m_form-error#personal_info-error {
  left: 50%;
  transform: translateX(-50%);
}
